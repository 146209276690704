import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { animated, useSpring, config } from 'react-spring';
import Layout from '../components/layout';
import GridItem from '../components/grid-item';
import SEO from '../components/SEO';
import { AnimatedBox } from '../elements';
import { ChildImageSharp } from '../types';

type PageProps = {
  data: {
    firstProject: {
      title: string;
      slug: string;
      cover: ChildImageSharp;
    };
    threeProjects: {
      nodes: {
        title: string;
        slug: string;
        cover: ChildImageSharp;
      }[];
    };
    aboutUs: ChildImageSharp;
    instagram: ChildImageSharp;
  };
};

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: 35vw 40vw 25vw; */
  grid-template-rows: 35vw 40vw;
  grid-template-areas:
    'first-project about-us about-us'
    'three-projects three-projects three-projects'
    'instagram instagram instagram';

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 35vw 30vw 30vw 25vw;
    grid-template-areas:
      'first-project first-project about-us about-us'
      'three-projects three-projects three-projects three-projects'
      'three-projects three-projects three-projects three-projects'
      'instagram instagram instagram instagram';
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 38vw);
    grid-template-areas:
      'first-project about-us'
      'three-projects three-projects'
      'three-projects three-projects'
      'three-projects three-projects'
      'instagram instagram';
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 50vw);
    grid-template-areas:
      'first-project'
      'about-us'
      'three-projects'
      'three-projects'
      'three-projects'
      'instagram';
  }
`;

const FirstProject = styled(GridItem)`
  grid-area: first-project;
`;

const Title = styled.h2`
  display: inline-block;
  position: absolute;
  right: 0;
  /* left: 0; */
  z-index: 10;
  margin: 0;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 1%, rgba(0, 0, 0, 0) 100%);
  padding: 2rem 1.5rem; */
  background-color: #f00;
  padding: 0.4rem 0.8rem;
  color: white;
  text-align: right;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: 700;
  font-size: 2.074rem;
`;

const AboutUs = styled(GridItem)`
  grid-area: about-us;
`;

const ThreeProjects = styled.div`
  grid-area: three-projects;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

const Instagram = styled(GridItem)`
  grid-area: instagram;
`;

const Index: React.FunctionComponent<PageProps> = ({ data: { firstProject, threeProjects, aboutUs, instagram } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Layout color="#fafafa">
      <SEO />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Agência LAVA</h1>
        <p>
          Com clientes no Brasil, Reino Unido e Estados Unidos, a Agência LAVA oferece serviços de Consultoria Digital,
          Desenvolvimento Web (WebDev) e Marketing Digital.
        </p>
        {/* <h1>Coronavírus (COVID-19)</h1>
        <p>
          Diante da pandemia do Coronavírus, a Agência LAVA está disponibilizando serviços gratuitos ou a preço de custo
          para Microempreendedores individuais (MEI) e pequenos empreendedores que não possuem uma presença online.
        </p>
        <p>
          Se a sua empresa foi altamente afetada pela pandemia, preencha esse formulário. Cada caso será analisado e
          entraremos em contato o mais brevemente possível. No momento, estamos dando prioridade para os empreendedores
          de Bauru.
        </p> */}
      </AnimatedBox>
      <Area style={pageAnimation}>
        <FirstProject to={`/projetos/${firstProject.slug}`} aria-label={`View project "${firstProject.title}"`}>
          <Img fluid={firstProject.cover.childImageSharp.fluid} />
          <Title>{firstProject.title}</Title>
        </FirstProject>
        <AboutUs to="/sobre" aria-label="Visit my about page">
          <Img fluid={aboutUs.childImageSharp.fluid} />
          <Title>Sobre</Title>
        </AboutUs>
        <ThreeProjects>
          {threeProjects.nodes.map((project) => (
            <GridItem
              to={`/projetos/${project.slug}`}
              key={`/projetos/${project.slug}`}
              aria-label={`View project "${project.title}"`}
            >
              <Img fluid={project.cover.childImageSharp.fluid} />
              <Title>{project.title}</Title>
            </GridItem>
          ))}
        </ThreeProjects>
        {/* <Instagram to="/instagram" aria-label="See my Instagram pictures">
          <Img fluid={instagram.childImageSharp.fluid} />
          <span>Instagram</span>
        </Instagram> */}
      </Area>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query Index {
    firstProject: projectsYaml {
      title
      slug
      cover {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    threeProjects: allProjectsYaml(limit: 3, skip: 1) {
      nodes {
        title
        slug
        cover {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    aboutUs: file(sourceInstanceName: { eq: "images" }, name: { eq: "about-us" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    instagram: file(sourceInstanceName: { eq: "images" }, name: { eq: "instagram" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
